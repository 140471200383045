/**
 * useLocalStorageObject
 *
 * A utility composable for managing objects in local storage.
 * This composable provides methods to set, get, and remove objects in local storage.
 * It ensures that values are serialized to JSON when storing and parsed back to objects when retrieving.
 *
 * Methods:
 * - set: Store an object in local storage under a specified key.
 * - get: Retrieve an object from local storage by its key.
 * - remove: Remove an object from local storage by its key.
 */
export const useLocalStorageObject = {
  set: (name: string, value: unknown) => localStorage.setItem(name, JSON.stringify(value)),
  get: (name: string) => localStorage.getItem(name)
    ? JSON.parse(localStorage.getItem(name) || '')
    : null,
  remove: (name: string) => localStorage.removeItem(name),
};
